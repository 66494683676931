import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ainsivalavie = () => (
  <Layout>
    <SEO title="Bananes Citronnées - Ainsi va la vie" />
    <h3 className='underline-title'>Ainsi va la vie</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Quarante trois saisons enterrées!<br />
        Aucune paillotte dans la cité!<br />
        Quelle déshéritante veulerie!<br />
        Comment entendre telle vilenie!<br />
      </p>
      <p>
        L'anathème est transparent:<br />
        Avoir mine d'ignorer ce qu'on ignore<br />
        Et de savoir ce qu'on sait encore<br />
        L'art-pétaudière n'en est point ainsi<br />
        Il faut faire le Séide voltairien:<br />
        Feindre d'ignorer ce qu'on sait<br />
        Et de savoir tout ce qu'on ignore<br />
        Conditions ou <span className="black-bold">Sésame ouvre-toi</span><br />
        Pour naître roturier<br />
        Et devenir gentilhomme<br />
      </p>
      <p>
        Jamis las de faire le zouave béat<br />
        MOKILI le saisit par l'épaule<br />
        Le secoue authentiquement:<br />
        Pitre, les mains se souillent autour de toi!<br />
        Et l'on exige que les tiennes soient propres!<br />
      </p>
      <p>
        À peine eut-il le temps de s'ébrouer<br /> 
        Les véreux renards doublaient le cap<br />
        Encore fallait-il enjamber à mort<br />
        À l'exemple du sprinter ABEBE-le-Kenyan<br />
        Pour rattraper les goupils<br />
        Fromage dans la guele<br />
      </p>
      <p>
        Juste au contour de la route fuyarde<br />
        Des mains sales avec sarcasme s'agitent<br />
        Acérées elles ont l'air de ricaner:<br />
        Oncle, tu as raté le tain, d'Al Capone!<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default ainsivalavie
